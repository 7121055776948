@import '../assets/colors';

// WRAPPER

// ngneat-dialog {
//     .ngneat-dialog-backdrop {
//         // backdrop styles
//         .ngneat-dialog-content {
//             width: 80% !important;
//             min-height: 80% !important;
//             .ngneat-drag-marker {
//                 // draggable marker
//             }
//             .ngneat-close-dialog {
//                 // 'X' icon for closing the dialog
//             }
//             .ngneat-dialog-primary-btn,
//             .ngneat-dialog-secondary-btn {
//                 // the default dialogs action buttons
//             }
//         }
//     }
// }

.shopping-cart-modal,
.observations-advice-modal,
.ticket-modal,
.videos-modal,
.video-player-modal,
.image-modal,
.i360-modal,
.simple-modal-dcp,
.simple-modal-catalogue,
.simple-modal-universal-kits,
.simple-modal-compliance-reports,
.simple-modal-mmr-calculation,
.simple-modal-recommended-kit,
.hitches-kits-comparative-modal,
.product-comparative-modal,
.assembly-estimate-modal,
.payment-methods-info-modal {
  .ngneat-dialog-backdrop {
    padding: 12px !important;
  }

  .ngneat-dialog-content {
    width: 1000px !important;
    min-height: 80% !important;
    padding: 24px;
    background: $gray-25;

    .ngneat-close-dialog {
      transform: translate(-8px, 16px);

      &:hover {
        color: $red-600 !important;
      }
    }
  }
}

.hitches-kits-comparative-modal {
  .ngneat-dialog-content {
    height: 100%;
    overflow-y: hidden !important;
  }
}

.simple-modal-recommended-kit,
.payment-methods-info-modal {
  .ngneat-dialog-content {
    width: 600px !important;
    min-height: auto !important;
  }
}

.simple-modal-mmr-calculation {
  .ngneat-dialog-content {
    width: fit-content !important;
    overflow-y: auto;
    min-height: auto !important;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.hitches-kits-comparative-modal,
.product-comparative-modal {
  .ngneat-dialog-content {
    width: fit-content !important;
    overflow-y: auto;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.assembly-estimate-modal {
  .ngneat-dialog-content {
    width: 100% !important;
    max-width: 1240px;
    height: 90vh;
    overflow-y: auto;
    padding: 0;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.image-modal {
  .ngneat-dialog-content {
    padding: 0;

    .ngneat-close-dialog {
      background: rgba(0, 0, 0, 0.35);
      border-radius: 1000px;
      padding: 24px;

      &::before {
        content: 'X';
      }
    }
  }
}

.video-player-modal {
    .ngneat-dialog-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .ngneat-close-dialog {
          border-radius: 1000px;
          padding: 24px;

          &::before {
            content: 'X';
          }
        }
      }
}

@media only screen and (max-width: 560px) {
  .shopping-cart-modal,
  .ticket-modal,
  .observations-advice-modal,
  .videos-modal,
  .simple-modal-dcp,
  .simple-modal-catalogue,
  .simple-modal-universal-kits,
  .simple-modal-compliance-reports,
  .assembly-estimate-modal,
  .hitches-kits-comparative-modal,
  .product-comparative-modal,
  .payment-methods-info-modal {
    .ngneat-dialog-content {
      padding: 12px;
    }
  }
}

@import '../assets/colors';

// GRAY

.color-white {
    color: $white;
}
.color-gray-25 {
    color: $gray-25;
}
.color-gray-50 {
    color: $gray-50;
}
.color-gray-100 {
    color: $gray-100;
}
.color-gray-200 {
    color: $gray-200;
}
.color-gray-300 {
    color: $gray-300;
}
.color-gray-400 {
    color: $gray-400;
}
.color-gray-500 {
    color: $gray-500;
}
.color-gray-600 {
    color: $gray-600;
}
.color-gray-700 {
    color: $gray-700;
}
.color-gray-800 {
    color: $gray-800;
}
.color-gray-900 {
    color: $gray-900;
}
.color-black {
    color: $black;
}

// ORANGE

.color-orange-400 {
    color: $orange-400;
}
.color-orange-500 {
    color: $orange-500;
}
.color-orange-600 {
    color: $orange-600;
}

// GREEN

.color-green-500 {
    color: $green-500;
}
.color-green-600 {
    color: $green-600;
}
.color-green-700 {
    color: $green-700;
}
.color-green-800 {
    color: $green-800;
}

// RED

.color-red-100 {
    color: $red-100;
}
.color-red-200 {
    color: $red-200;
}
.color-red-300 {
    color: $red-300;
}
.color-red-400 {
    color: $red-400;
}
.color-red-500 {
    color: $red-500;
}
.color-red-600 {
    color: $red-600;
}
.color-red-700 {
    color: $red-700;
}

// BLUE

.color-blue-500 {
    color: $blue-500;
}
.color-blue-600 {
    color: $blue-600;
}

// YELLOW

.color-yellow-500 {
    color: $yellow-500;
}

// - BACKGROUND -

// GRAY

.background-white {
    background: $white;
}
.background-gray-25 {
    background: $gray-25;
}
.background-gray-50 {
    background: $gray-50;
}
.background-gray-100 {
    background: $gray-100;
}
.background-gray-200 {
    background: $gray-200;
}
.background-gray-300 {
    background: $gray-300;
}
.background-gray-400 {
    background: $gray-400;
}
.background-gray-500 {
    background: $gray-500;
}
.background-gray-600 {
    background: $gray-600;
}
.background-gray-700 {
    background: $gray-700;
}
.background-gray-800 {
    background: $gray-800;
}
.background-gray-900 {
    background: $gray-900;
}
.background-black {
    background: $black;
}

// ORANGE

.background-orange-100 {
    background: $orange-100;
}
.background-orange-200 {
    background: $orange-200;
}
.background-orange-300 {
    background: $orange-300;
}
.background-orange-400 {
    background: $orange-400;
}
.background-orange-500 {
    background: $orange-500;
}
.background-orange-600 {
    background: $orange-600;
}

// GREEN

.background-green-50 {
    background: $green-50;
}
.background-green-500 {
    background: $green-500;
}
.background-green-600 {
    background: $green-600;
}
.background-green-700 {
    background: $green-700;
}
.background-green-800 {
    background: $green-800;
}

// RED

.background-red-100 {
    background: $red-100;
}
.background-red-200 {
    background: $red-200;
}
.background-red-300 {
    background: $red-300;
}
.background-red-400 {
    background: $red-400;
}
.background-red-500 {
    background: $red-500;
}
.background-red-600 {
    background: $red-600;
}

// BLUE

.background-blue-50 {
    background: $blue-50;
}
.background-blue-500 {
    background: $blue-500;
}
.background-blue-600 {
    background: $blue-600;
}

// YELLOW

.background-yellow-50 {
    background: $yellow-50;
}
.background-yellow-500 {
    background: $yellow-500;
}

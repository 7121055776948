@media only screen and (max-width: 800px) {
    .container-parent-client-area {
        .child-container {
            .header {
                div {
                    gap: 12px !important;
                }
            }
        }
    }
}

.child-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        div {
            display: flex;
            gap: 24px;
        }
    }
}

@media only screen and (max-width: 640px) {
    .container-parent-client-area {
        .grid-space {
            margin: 0;
        }
        .container-data {
            margin-top: 0 !important;
            padding: 24px !important;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        ::-webkit-scrollbar-track {
            border-top-right-radius: 0;
        }
        ::-webkit-scrollbar-thumb {
            border-top-right-radius: 0;
        }
    }
}

@media only screen and (max-width: 600px) {
    .container-card-products {
        width: 100%;
        ea-card-product {
            width: 100%;
        }
    }
    .card-container {
        padding: 12px !important;
        padding-bottom: 0 !important;
    }
}

@media only screen and (max-width: 400px) {
    .grid-space {
        margin: 12px 24px;
    }
}

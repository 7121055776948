$white: #ffffff;
$gray-25: #fdfdfd;
$gray-50: #fafafa;
$gray-100: #f4f4f5;
$gray-200: #e4e4e7;
$gray-300: #d4d4d8;
$gray-400: #a1a1aa;
$gray-500: #71717a;
$gray-600: #52525b;
$gray-700: #3f3f46;
$gray-800: #27272a;
$gray-900: #18181b;
$black: #000000;

$orange-50: #fff7ed;
$orange-100: #ffedd5;
$orange-200: #fed7aa;
$orange-300: #fdba74;
$orange-400: #fb923c;
$orange-500: #f97316;
$orange-600: #ea580c;

$green-50: #f0fdf4;
$green-500: #22c55e;
$green-600: #16a34a;
$green-700: #047857;
$green-800: #065f46;

$yellow-500: #eab308;

$red-100: #fee2e2;
$red-200: #fecaca;
$red-300: #fca5a5;
$red-400: #f87171;
$red-500: #ef4444;
$red-600: #dc2626;
$red-700: #9d231f;

$blue-50: #eff6ff;
$blue-500: #3b82f6;
$blue-600: #2563eb;

$yellow-50: #fefce8;

@font-face {
    font-family: 'Icons';
    src: local('Icons'),
        url(https://storage.enganchesaragon.com/public-websites/ecommerce/fonts/Aragon_NWEA.ttf)
            format('truetype');
}

@font-face {
    font-family: 'Calibri';
    src: local('Calibri'), url(../assets/fonts/calibri/calibri-regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Consolas';
    src: local('Consolas'), url(../assets/fonts/consolas/consolas-regular.ttf) format('truetype');
}

* {
    font-family: 'Calibri';
}

.font-family-consolas {
    font-family: 'Consolas';
}

.font-family-saira {
    font-family: 'Saira Semi Condensed';
}

[data-icon]:before {
    font-family: 'Icons';
    content: attr(data-icon);
}

// Weights
.text-xs-thin,
.text-sm-thin,
.text-base-thin,
.text-lg-thin,
.text-xl-thin,
.text-2xl-thin,
.text-3xl-thin,
.text-4xl-thin,
.text-5xl-thin,
.text-6xl-thin {
    font-weight: 100;
}
.text-xs-light,
.text-sm-light,
.text-base-light,
.text-lg-light,
.text-xl-light,
.text-2xl-light,
.text-3xl-light,
.text-4xl-light,
.text-5xl-light,
.text-6xl-light {
    font-weight: 300;
}
.text-xs-regular,
.text-sm-regular,
.text-base-regular,
.text-lg-regular,
.text-xl-regular,
.text-2xl-regular,
.text-3xl-regular,
.text-4xl-regular,
.text-5xl-regular,
.text-6xl-regular {
    font-weight: 400;
}
.text-xs-medium,
.text-sm-medium,
.text-base-medium,
.text-lg-medium,
.text-xl-medium,
.text-2xl-medium,
.text-3xl-medium,
.text-4xl-medium,
.text-5xl-medium,
.text-6xl-medium {
    font-weight: 500;
}
.text-xs-semibold,
.text-sm-semibold,
.text-base-semibold,
.text-lg-semibold,
.text-xl-semibold,
.text-2xl-semibold,
.text-3xl-semibold,
.text-4xl-semibold,
.text-5xl-semibold,
.text-6xl-semibold {
    font-weight: 600;
}
.text-xs-bold,
.text-sm-bold,
.text-base-bold,
.text-lg-bold,
.text-xl-bold,
.text-2xl-bold,
.text-3xl-bold,
.text-4xl-bold,
.text-5xl-bold,
.text-6xl-bold {
    font-weight: 700;
}
.text-xs-black,
.text-sm-black,
.text-base-black,
.text-lg-black,
.text-xl-black,
.text-2xl-black,
.text-3xl-black,
.text-4xl-black,
.text-5xl-black,
.text-6xl-black {
    font-weight: 900;
}

// XS
.text-xs-thin,
.text-xs-light,
.text-xs-regular,
.text-xs-medium,
.text-xs-semibold,
.text-xs-bold,
.text-xs-black {
    font-size: 12px;
}

// SM
.text-sm-thin,
.text-sm-light,
.text-sm-regular,
.text-sm-medium,
.text-sm-semibold,
.text-sm-bold,
.text-sm-black {
    font-size: 14px;
}

// BASE
.text-base-thin,
.text-base-light,
.text-base-regular,
.text-base-medium,
.text-base-semibold,
.text-base-bold,
.text-base-black {
    font-size: 16px;
}

// LG
.text-lg-thin,
.text-lg-light,
.text-lg-regular,
.text-lg-medium,
.text-lg-semibold,
.text-lg-bold,
.text-lg-black {
    font-size: 18px;
}

// XL
.text-xl-thin,
.text-xl-light,
.text-xl-regular,
.text-xl-medium,
.text-xl-semibold,
.text-xl-bold,
.text-xl-black {
    font-size: 20px;
}

// 2XL
.text-2xl-thin,
.text-2xl-light,
.text-2xl-regular,
.text-2xl-medium,
.text-2xl-semibold,
.text-2xl-bold,
.text-2xl-black {
    font-size: 24px;
}

// 3XL
.text-3xl-thin,
.text-3xl-light,
.text-3xl-regular,
.text-3xl-medium,
.text-3xl-semibold,
.text-3xl-bold,
.text-3xl-black {
    font-size: 30px;
}

// 4XL
.text-4xl-thin,
.text-4xl-light,
.text-4xl-regular,
.text-4xl-medium,
.text-4xl-semibold,
.text-4xl-bold,
.text-4xl-black {
    font-size: 36px;
}

// 5XL
.text-5xl-thin,
.text-5xl-light,
.text-5xl-regular,
.text-5xl-medium,
.text-5xl-semibold,
.text-5xl-bold,
.text-5xl-black {
    font-size: 48px;
}

// 6XL
.text-6xl-thin,
.text-6xl-light,
.text-6xl-regular,
.text-6xl-medium,
.text-6xl-semibold,
.text-6xl-bold,
.text-6xl-black {
    font-size: 64px;
}

@media only screen and (max-width: 560px) {
    // XS
    .text-xs-thin,
    .text-xs-light,
    .text-xs-regular,
    .text-xs-medium,
    .text-xs-semibold,
    .text-xs-bold,
    .text-xs-black {
        font-size: 10px;
    }

    // SM
    .text-sm-thin,
    .text-sm-light,
    .text-sm-regular,
    .text-sm-medium,
    .text-sm-semibold,
    .text-sm-bold,
    .text-sm-black {
        font-size: 12px;
    }

    // BASE
    .text-base-thin,
    .text-base-light,
    .text-base-regular,
    .text-base-medium,
    .text-base-semibold,
    .text-base-bold,
    .text-base-black {
        font-size: 14px;
    }

    // LG
    .text-lg-thin,
    .text-lg-light,
    .text-lg-regular,
    .text-lg-medium,
    .text-lg-semibold,
    .text-lg-bold,
    .text-lg-black {
        font-size: 16px;
    }

    // XL
    .text-xl-thin,
    .text-xl-light,
    .text-xl-regular,
    .text-xl-medium,
    .text-xl-semibold,
    .text-xl-bold,
    .text-xl-black {
        font-size: 18px;
    }

    // 2XL
    .text-2xl-thin,
    .text-2xl-light,
    .text-2xl-regular,
    .text-2xl-medium,
    .text-2xl-semibold,
    .text-2xl-bold,
    .text-2xl-black {
        font-size: 22px;
    }

    // 3XL
    .text-3xl-thin,
    .text-3xl-light,
    .text-3xl-regular,
    .text-3xl-medium,
    .text-3xl-semibold,
    .text-3xl-bold,
    .text-3xl-black {
        font-size: 28px;
    }

    // 4XL
    .text-4xl-thin,
    .text-4xl-light,
    .text-4xl-regular,
    .text-4xl-medium,
    .text-4xl-semibold,
    .text-4xl-bold,
    .text-4xl-black {
        font-size: 34px;
    }

    // 5XL
    .text-5xl-thin,
    .text-5xl-light,
    .text-5xl-regular,
    .text-5xl-medium,
    .text-5xl-semibold,
    .text-5xl-bold,
    .text-5xl-black {
        font-size: 46px;
    }

    // 6XL
    .text-6xl-thin,
    .text-6xl-light,
    .text-6xl-regular,
    .text-6xl-medium,
    .text-6xl-semibold,
    .text-6xl-bold,
    .text-6xl-black {
        font-size: 62px;
    }
}

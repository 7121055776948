@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~primeng/resources/primeng.min.css';
@import '~primeng/resources/themes/saga-blue/theme.css';
// @import '~primeicons/primeicons.css';
@import '../assets/colors';

// - CUSTOM -

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background-color: $gray-200;
}

::-webkit-scrollbar-thumb {
    background-color: $gray-900;
}

::-webkit-scrollbar-button {
    display: none;
}

table {
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th,
    td {
        padding: 16px;
    }

    tbody {
        td {
            p {
                text-align: left;
            }
        }
    }
}

a {
    cursor: pointer;
}

form {
    width: 100%;
}

input {
    outline: none !important;
}

input:focus {
    //border: none !important;
}

button {
    outline: none !important;
}

.animated {
    visibility: visible !important;
}

.section-header:hover {
    a {
        color: $orange-600;
        font-weight: 500;
    }

    a:not(.active-section) {
        color: white;
    }
}

sub {
    bottom: 0;
}

.colors-tr {
    .data-colors {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;

        .color {
            display: inline-block;
            width: 32px;
            height: 32px;
            border: 2px solid $gray-900;
            outline: none !important;
            background: $gray-100;
            border-radius: 1000px;
            cursor: pointer;

            &:hover {
                .tooltip-text {
                    visibility: visible;
                }
            }

            .tooltip-text {
                position: relative;
                display: inline-block;
                visibility: hidden;
                width: 120px;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
                position: absolute;
                z-index: 1;
                top: 100%;
                left: 50%;
                margin-left: -60px;
                background-color: $gray-800;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

.container-parent-client-area {
    display: flex;
    height: 100%;

    .container-data {
        width: 100%;
        overflow: auto;
        margin-bottom: 0;
        padding: 24px;
        overflow-y: hidden;
    }

    ::-webkit-scrollbar-track {
        border-top-right-radius: 12px;
    }

    ::-webkit-scrollbar-thumb {
        border-top-right-radius: 12px;
    }
}

.grid-space {
    margin: 24px 48px;
}

.opacity-animation {
    animation: appearIn 0.4s ease-out both;
}

@keyframes appearIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.appear-animation {
    animation: appearIn 0.4s ease-out both;
}

@keyframes appearIn {
    0% {
        transform: translateY(48px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.appear-animation-slow {
    animation: appearInSlow 0.4s ease-out both;
}

@keyframes appearInSlow {
    0% {
        transform: translateY(24px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.container-select-list li:hover {
    background: $gray-50;
}

.type-select-hitches button:focus {
    background: $gray-100;
}

button.button-base {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    transition: 0.4s;

    &:hover {
        transition: 0.4s;
        transform: scale(1.05);
    }
}

.lottie-container {
    display: flex;
    justify-content: center;
}

.lottie-main-container {
    width: 132px;
    height: 132px;
}

.router-history {
    padding: 24px;
}

.router-history-section {
    position: absolute;
    background-color: rgba($gray-900, 0.3);
    width: 100%;
    z-index: 1;

    ea-router-history {
        a {
            color: $gray-50 !important;
            opacity: 0.9;
        }
    }
}

.router-history {
    margin-top: 164px;
}

.display-none {
    display: none !important;
}

.text-categories-list-inner {
    padding: 16px;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    white-space: normal;

    p {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        white-space: normal;
    }
}

#carousel-container {
    .column {
        .features {
            .feature {
                display: flex;
                align-items: center;
                gap: 16px;
                width: 240px;

                img {
                    width: 48px;
                    height: 48px;
                }

                > .icon {
                    font-size: 37px;
                    color: $orange-600;
                }
            }
        }
    }
}

.container-modal-comparative-products {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;

    * {
        font-family: 'Saira Semi Condensed';
    }

    .content {
        height: 100%;
        overflow: auto;

        .table {
            display: block;
            overflow: auto;
            padding: 0 1px;
            border-radius: 12px;
        }

        table {
            min-width: 1100px;

            th:not(.empty),
            td {
                border: 1px solid $gray-300;
                padding: 8px 16px;
                min-width: 170px;
                max-width: 200px;
                width: auto !important;

                &[scope='row'] {
                    min-height: 64px;
                }
            }

            thead {
                tr {
                    th {
                        min-width: 180px !important;
                        padding: 16px 0;

                        > p {
                            margin-bottom: 12px;
                        }

                        > .image {
                            max-width: 220px;
                            max-height: 220px;
                            margin: 0 auto;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 4px;
                                object-fit: contain;
                            }
                        }

                        .recommended-kit-label {
                            position: relative;
                            display: inline-block;

                            &:hover {
                                .tooltip-text {
                                    visibility: visible;
                                }
                            }

                            .tooltip-text {
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                gap: 6px;
                                padding: 6px;
                                visibility: hidden;
                                width: fit-content;
                                text-align: center;
                                border-radius: 6px;
                                position: absolute;
                                z-index: 1;
                                top: 100%;
                                left: 20%;
                                margin-left: -60px;
                            }
                        }

                        div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 12px;

                            img {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        > img {
                            width: 40px;
                            height: 40px;
                            margin: 0;
                        }

                        > .icon {
                            font-size: 32px;
                            color: $orange-600;
                        }

                        h2 {
                            text-align: left;
                        }

                        .container-docs {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 12px;

                            .doc {
                                display: flex;
                                align-items: center;
                                gap: 12px;
                                cursor: pointer;

                                img {
                                    width: 32px;
                                    height: 32px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    th.empty {
        position: relative;

        p {
            position: absolute;
            top: 16px;
        }
    }

    th:nth-child(odd),
    td:nth-child(odd) {
        background: $gray-200;
    }

    th:nth-child(even),
    td:nth-child(even) {
        background: $gray-100;
    }
}

// ea-navbar,
ea-header {
    > div {
        position: fixed !important;
        z-index: 99 !important;
        width: 100%;
    }
}

.header-top-space {
    padding-top: 164px;
}

.container-text-overflow-dots {
    p {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-align: justify;
        white-space: normal;
    }
}

.container-sheet {
    ul {
        list-style: disc;

        li {
            text-align: justify;

            * {
                text-align: justify;
            }
        }
    }
}

ea-faqs {
    min-width: 50%;
}

.explanation-long-section {
    p {
        font-family: 'Saira Semi Condensed';
    }

    h2 {
        font-family: 'Saira Semi Condensed';
        font-size: 20px;
    }
}

.carousel-home {
    width: 100%;
    max-width: 1300px;

    .p-carousel-items-content {
        border-radius: 6px;

        .p-carousel-item {
            .product-item {
                background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
                    var(--image);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 400px;
                width: 400px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                        var(--image);

                    h2 {
                        color: $orange-600;
                    }
                }
            }
        }
    }

    .p-carousel-indicators {
        .p-carousel-indicator:not(.p-highlight) {
            > button {
                background: $gray-300 !important;

                &:hover {
                    background: $gray-400 !important;
                }
            }
        }

        .p-highlight {
            button {
                background-color: $orange-600 !important;
            }
        }
    }

    button {
        &.p-carousel-prev {
            &::before {
                content: '❮';
                transform: scale(2);
                color: $orange-600;
            }
        }

        &.p-carousel-next {
            &::before {
                content: '❯';
                transform: scale(2);
                color: $orange-600;
            }
        }

        &:focus {
            box-shadow: none !important;
            background-color: none !important;
        }

        .pi {
            color: $orange-600 !important;
            transform: scale(1.5);
        }
    }
}

.box-shadow-0-4-4-25 {
    box-shadow: 0 4px 4px rgb(0, 0, 0, 25%);
}

.box-shadow-0-2-8-25 {
    box-shadow: 0 2px 8px rgb(0, 0, 0, 25%);
}

.box-shadow-0-2-8-15 {
    box-shadow: 0 2px 8px rgb(0, 0, 0, 15%);
}

.box-shadow-0-2-8-5 {
    box-shadow: 0 2px 8px rgb(0 0 0 / 5%);
}

.box-shadow-0-2-4-10 {
    box-shadow: 0 2px 4px rgb(0, 0, 0, 10%);
}

.box-shadow-4-0-4-25 {
    box-shadow: 4px 0 4px rgb(0, 0, 0, 25%);
}

.box-shadow-0-0-16-10 {
    box-shadow: 0 0 16px rgb(0, 0, 0, 10%);
}

.box-shadow-0-0-16-50 {
    box-shadow: 0 0 16px rgb(0, 0, 0, 50%);
}

.box-shadow-0-0-16-15 {
    box-shadow: 0 0 16px rgb(0, 0, 0, 15%);
}

@media only screen and (max-width: 1400px) {
    .header-top-space {
        padding-top: 80px;
    }
    .sidebar {
        height: 81vh !important;
        top: 162px !important;
    }
    .router-history {
        margin-top: 80px !important;
    }
}

@media only screen and (max-width: 1330px) {
    .carousel-home {
        .p-carousel-items-content {
            .p-carousel-item {
                .product-item {
                    width: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .carousel-home {
        .p-carousel-items-content {
            .p-carousel-items-container {
                max-width: 260px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .vehicle-card-with-accordions-container {
        padding: 0 !important;
        padding-bottom: 24px !important;
        margin: 0 !important;
    }
}
